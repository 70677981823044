import React from 'react'
import { graphql } from 'gatsby'

import { Hero, Modal, Navigation, Adress, SectionList } from '../components'

import { DefaultLayout } from '../layouts/defaultlayout'

const menu = [
  { title: 'Über mich', path: '#ueber' },
  { title: 'Psychotherapie', path: '#psychotherapie' },
  { title: 'Kosten', path: '#kosten' },
  { title: 'Risiken und Nebenwirkungen', path: '#risiken' },
  { title: 'Kontakt', path: '#anfahrt' },
  { title: 'Frauenkreis', path: '#meditation' },
]

export const query = graphql`
  query {
    hero: directus {
      ...HeroSectionFragment
    }
    modal: directus {
      ...PopupFragment
    }
    logo: directus {
      ...LogoFragment
    }
    adress: directus {
      ...AdressFragment
    }
    pricing: directus {
      ...PricingFragment
    }
    indexbody: directus {
      ...IndexBodyFragment
    }
  }
`

// eslint-disable-next-line import/no-default-export
export default ({ data }: any) => {
  return (
    <DefaultLayout>
      <Modal
        title={data.modal.popup.title}
        text={data.modal.popup.text}
        buttonYes={data.modal.popup.confirmation}
        isModalEnabled={data.modal.popup.enabled}
      />
      <Hero backgroundImage={data.hero.herosection.picture.imageFile} anchor="ueber" />
      <Navigation title="Psychotherapie-Norpoth" menu={menu} logo={data.logo.logo.logo.imageFile} />
      <SectionList sectionlist={data.indexbody.indexbody} accordion={data.pricing.Pricing} />
      <Adress adress={data.adress.adress} />
    </DefaultLayout>
  )
}
